<template>
  <div>
    <v-dialog :value="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title
          class="headline indigo darken-4 white--text justify-space-between"
          primary-title
          >Visualizar Solicitud {{ dataModal.consecutive }}
          <v-btn class="float-right" icon dark @click="$emit('handleClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px">
          <v-container>
            <v-row>
              <v-flex xs12>
                <v-subheader class="title font-weight-bold">{{
                  title == "CT"
                    ? "Concepto Técnico"
                    : title == "CP"
                    ? "Certificado de Patente de Pesca"
                    : title == "IT"
                    ? "Inspección Técnica"
                    : ""
                }}</v-subheader>
              </v-flex>
              <v-col cols="6">
                <v-text-field
                  :value="
                    dataModal.lugar != undefined
                      ? dataModal.lugar != null
                        ? dataModal.lugar.nombre
                        : ''
                      : ''
                  "
                  label="Lugar"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :value="dataModal.expedition_date"
                  label="Fecha de Expedición"
                  readonly
                ></v-text-field>
              </v-col>

              <v-flex xs12 v-if="title != 'IT'">
                <v-subheader class="title font-weight-bold"
                  >Datos de la Solicitud</v-subheader
                >
              </v-flex>

              <v-col cols="4" v-if="title != 'IT'">
                <v-text-field
                  :value="dataModal.filed"
                  label="Radicado"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="title != 'IT'">
                <v-text-field
                  :value="dataModal.date_filing"
                  label="Fecha de Radicado"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="title != 'IT'">
                <v-text-field
                  :value="dataModal.date_application_assignment"
                  label="Fecha de Asignación Solicitud"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="title == 'CT' && dataModal.type != 'ART'">
                <v-text-field
                  :value="
                    dataModal.tipo_autorizacion != undefined
                      ? dataModal.tipo_autorizacion != null
                        ? dataModal.tipo_autorizacion.nombre
                        : ''
                      : ''
                  "
                  label="Tipo de Autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="title != 'IT'">
                <v-text-field
                  :value="(dataModal.request_type || {}).nombre || ''"
                  label="Tipo de solicitud"
                  readonly
                ></v-text-field>
              </v-col>

              <v-flex xs12 v-if="title != 'IT'">
                <v-subheader class="title font-weight-bold"
                  >Documentos de la Solicitud</v-subheader
                >
              </v-flex>

              <v-col cols="4" v-if="title != 'IT'">
                <p>
                  <b>Carta de solicitud:</b>
                  {{ dataModal.request_letter }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Camara de comercio:</b>
                  {{ dataModal.chamber_of_commerce }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Copia del documento de identificación:</b>
                  {{ dataModal.identification_copy_doc }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT' && dataModal.type == 'ART'">
                <p>
                  <b>Copia de la ocre:</b>
                  {{ dataModal.ocre_copy }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Plan de actividades:</b>
                  {{ dataModal.activities_plan }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Fotocopia tarjeta o matricula profesional:</b>
                  {{ dataModal.photocopy_professional_card }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT' && dataModal.type == 'IND'">
                <p>
                  <b>Certificacion empresa que procesara las capturas:</b>
                  {{ dataModal.certificado_captura }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Copia de la matricula de la motonave(s):</b>
                  {{ dataModal.motorcycle_enrollment_copy }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT' && dataModal.type == 'ART'">
                <p>
                  <b>Contrato de afiliación de la motonave(s):</b>
                  {{ dataModal.motorcycle_affiliation_cont }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title == 'CT'">
                <p>
                  <b>Infome anual de actividades:</b>
                  {{ dataModal.annual_activity_report }}
                </p>
              </v-col>
              <v-col cols="4" v-if="title != 'IT'">
                <p>
                  <b>Visita técnica de motonave(s):</b>
                  {{ dataModal.motorcycle_technical_visit }}
                </p>
              </v-col>

              <v-flex xs12>
                <v-subheader class="title font-weight-bold"
                  >Datos del Permisionario</v-subheader
                >
              </v-flex>

              <v-row
                class="px-2"
                v-if="dataModal.requestable_type !== requestables.FISHERMEN"
              >
                <v-col cols="4">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.name
                        : ''
                    "
                    label="Permisionario o Titular del Permiso"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="dataModal.company != null">
                  <v-text-field
                    :value="
                      dataModal.company_id + '-' + dataModal.company.cod_nit
                    "
                    label="Nit"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.type_of_company == 'UNO'
                          ? 'NATURAL'
                          : dataModal.company.type_of_company == 'DOS'
                          ? 'JURIDICA'
                          : ''
                        : ''
                    "
                    label="Tipo de Empresa"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.legal_representative
                        : ''
                    "
                    label="Representante legal"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.id_type
                        : ''
                    "
                    label="Tipo de identificación"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.identification_number
                        : ''
                    "
                    label="Número de identificación"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.permission != undefined
                        ? dataModal.company.address
                        : ''
                    "
                    label="Dirección"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.email
                        : ''
                    "
                    label="Email"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="4" v-if="title != 'IT'">
                  <v-text-field
                    :value="
                      dataModal.company != undefined
                        ? dataModal.company.phone
                        : ''
                    "
                    label="Teléfono"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  v-if="title != 'IT' && dataModal.type == 'ART'"
                >
                  <v-text-field
                    :value="((dataModal.company || {}).certifying_organization || {}).abbr_name"
                    label="Organización cooperativa"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-2" v-else>
                <v-col cols="4">
                  <v-text-field
                    v-model="dataModal.requestable.id_type"
                    hint="Tipo de documento"
                    clearable
                    persistent-hint
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="dataModal.requestable.identification_number"
                    prepend-inner-icon="fa-user"
                    hint="Número de documento"
                    clearable
                    persistent-hint
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="dataModal.requestable.name"
                    prepend-inner-icon="fa-user"
                    hint="Nombre Completo"
                    clearable
                    persistent-hint
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="dataModal.requestable.phone"
                    prepend-inner-icon="phone"
                    hint="phone"
                    clearable
                    persistent-hint
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="dataModal.requestable.organization.abbr_name"
                    prepend-inner-icon="business"
                    hint="Organización (Nombre abreviado)"
                    clearable
                    persistent-hint
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-flex xs12 v-if="title != 'IT'">
                <v-subheader class="title font-weight-bold"
                  >Permiso de Pesca</v-subheader
                >
              </v-flex>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.authorization_number
                      : ''
                  "
                  label="Número de Autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.date_of_issue
                      : ''
                  "
                  label="Fecha de emisión"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.date_of_notification
                      : ''
                  "
                  label="Fecha de notificación"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.expedido_por != null
                        ? dataModal.permission.expedido_por.nombre
                        : ''
                      : ''
                  "
                  label="Expedido por"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.titular_autorizacion != null
                        ? dataModal.permission.titular_autorizacion.nombre
                        : ''
                      : ''
                  "
                  label="Titular de la autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.effective_activation_date
                      : ''
                  "
                  label="Fecha inicio autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.effective_inactivation_date
                      : ''
                  "
                  label="Fecha fin autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.duration_of_permit_granted == 'UNO'
                        ? 1
                        : dataModal.permission.duration_of_permit_granted ==
                          'DOS'
                        ? 2
                        : dataModal.permission.duration_of_permit_granted ==
                          'TRES'
                        ? 3
                        : dataModal.permission.duration_of_permit_granted ==
                          'CUATRO'
                        ? 4
                        : dataModal.permission.duration_of_permit_granted ==
                          'CINCO'
                        ? 5
                        : ''
                      : ''
                  "
                  label="Vigencia del Permiso (años)"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? dataModal.permission.zona_autorizada != null
                        ? dataModal.permission.zona_autorizada.nombre
                        : ''
                      : ''
                  "
                  label="Zona autorizada"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3" v-if="title != 'IT' && dataModal.type == 'IND'">
                <v-text-field
                  :value="
                    dataModal.permission != undefined
                      ? (concepto.items_select_authorized_landing_port_ind || []).find(
                          (x) => x.codigo == dataModal.permission.landing_zone
                        ) != undefined
                        ? (concepto.items_select_authorized_landing_port_ind || []).find(
                            (x) => x.codigo == dataModal.permission.landing_zone
                          ).nombre
                        : ''
                      : ''
                  "
                  label="Puerto autorizado"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="title != 'IT' && dataModal.type == 'ART'">
                <v-text-field
                  :value="
                    ((dataModal.permission || {}).puerto_desembarque_artesanal || {})
                      .nombre
                  "
                  label="Puerto autorizado"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="6" v-if="title != 'IT'">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Tipo de pesquera</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in dataModal.permission != undefined
                        ? dataModal.permission.type_fishery != null
                          ? dataModal.permission.type_fishery
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.pivot.description"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col cols="6" v-if="title != 'IT'">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          >Especies Autorizadas</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in dataModal.permission != undefined
                        ? dataModal.permission.especies_autorizadas != null
                          ? dataModal.permission.especies_autorizadas
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>

              <v-col cols="6" v-if="title != 'IT'">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Artes de Pesca</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="(item, index) in dataModal.permission != undefined
                        ? dataModal.permission.fishing_arts != null
                          ? dataModal.permission.fishing_arts
                          : array_e
                        : array_e"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.pivot.description"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>

              <v-col cols="6" v-if="title != 'IT'">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Artes Autorizadas</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in dataModal.permission != undefined
                        ? dataModal.permission.authorized_arts != null
                          ? dataModal.permission.authorized_arts.split(',')
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            items_select_authorized_arts.find(
                              (x) => x.abbr == item
                            ) != undefined
                              ? items_select_authorized_arts.find(
                                  (x) => x.abbr == item
                                ).state
                              : ''
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col
                cols="6"
                v-if="dataModal.request_type != 'SIETE' && title == 'CT'"
              >
                <v-text-field
                  v-model="dataModal.valor_tasa_anual"
                  hint="Valor de tasa anual ($)"
                  persistent-hint
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="dataModal.request_type != 'SIETE' && title == 'CT'"
              >
                <v-text-field
                  v-model="valor_tasa"
                  hint="Valor de tasa anual en letras($)"
                  persistent-hint
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                v-if="
                  dataModal.anexo != null &&
                  dataModal.request_type != 'SIETE' &&
                  title == 'CT'
                "
              >
                <a :href="apiDomain + dataModal.anexo" target="_blank">
                  <v-btn
                    color="red darken-1"
                    text
                    @click="$emit('handleCertiPDF', 'Ok')"
                    v-if="download"
                    >Resolución (PDF)</v-btn
                  >
                </a>
              </v-col>

              <v-flex xs12>
                <v-subheader class="title font-weight-bold"
                  >Barcos Asociados</v-subheader
                >
              </v-flex>

              <v-col v-if="!dataModal.boat">
                <v-expansion-panels
                  v-for="(boats_group, i) in boats"
                  :key="`${boats_group.code}_${i}`"
                >
                  <v-subheader class="title font-weight-bold text-left">{{
                    boats_group.title
                  }}</v-subheader>
                  <v-expansion-panel
                    v-for="(barco, i) in boats_group.data"
                    :key="`${barco.registration_number}_${i}`"
                  >
                    <v-expansion-panel-header v-slot="{}">
                      <v-row no-gutters>
                        <v-col cols="4"
                          >{{ barco.boat_name }} |
                          {{ barco.registration_number }}</v-col
                        >
                        <v-col cols="3">
                          <v-icon>fa-ship</v-icon>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Datos del Registro</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.registration_number"
                            hint="Número de registro"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.boat_name"
                            hint="Nombre de la Embarcación"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                           v-if="concepto.items_select_registration_port"
                            :value="
                              (concepto.items_select_registration_port || []).find(
                                (x) => x.abbr == barco.registration_port
                              ) != undefined
                                ? (concepto.items_select_registration_port || []).find(
                                    (x) => x.abbr == barco.registration_port
                                  ).state
                                : barco.registration_port
                            "
                            hint="Puerto de registro"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="dataModal.type == 'IND'">
                          <v-text-field
                            :value="
                              barco.fishing_port_state
                                ? barco.fishing_port_state
                                : (
                                    (concepto.items_select_authorized_landing_port_ind || []).find(
                                      (x) => x.codigo == barco.codigo
                                    ) || {}
                                  ).nombre
                            "
                            hint="Puerto de desembarque"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-else>
                          <v-text-field
                            :value="
                              barco.fishing_port_state
                                ? barco.fishing_port_state
                                : (
                                    (concepto.items_select_authorized_landing_port || []).find(
                                      (x) => x.codigo == barco.fishing_port
                                    ) || {}
                                  ).nombre
                            "
                            hint="Puerto de desembarque"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              barco.boat_type_state != null
                                ? barco.boat_type_state
                                : barco.boat_type != null
                                ? barco.boat_type.nombre
                                : ''
                            "
                            hint="Tipo de barco"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="
                              barco.current_state_pavilion != null
                                ? (concepto.items_select_current_state_pavilion || []).find(
                                    (x) =>
                                      x.abbr == barco.current_state_pavilion
                                  ) != undefined
                                  ? (concepto.items_select_current_state_pavilion || []).find(
                                      (x) =>
                                        x.abbr == barco.current_state_pavilion
                                    ).state
                                  : ''
                                : ''
                            "
                            hint="Estado del pabellón"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="barco.owner_name"
                            hint="Propietario"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              barco.owner_type_id +
                              ':' +
                              barco.owner_identification
                            "
                            hint="Identificación del Propietario"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.expedition_date"
                            hint="Fecha de Expedición de la Matricula"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.expiration_date"
                            hint="Fecha de Vencimineto de la Matricula"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.approx_boat_value"
                            hint="Valor Aproximado de la Embarcación"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.sell_products_to"
                            hint="Venderá los Productos a"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Dimensiones</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.length_overall"
                            readonly
                            prepend-inner-icon
                            placeholder="ESLORA - Longitud total (LOA)(m)"
                            hint="ESLORA - Longitud total (LOA)(m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.beam_extreme_breadth"
                            readonly
                            prepend-inner-icon
                            placeholder="Manga / extrema amplitud (m)"
                            hint="Manga / extrema amplitud (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.net_registered_tonnage"
                            readonly
                            prepend-inner-icon
                            hint="Tonelaje de Registro Neto (TRN)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.gross_registered_tonnage"
                            prepend-inner-icon
                            readonly
                            hint="Tonelaje de Registro Bruto (TRB)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="barco.type_boat == 'ART'">
                          <v-text-field
                            :value="
                              barco.type_of_propulsion_state ||
                              (barco.type_of_propulsion || {}).nombre
                            "
                            readonly
                            hint="Tipo de propulsión."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.main_engines_power"
                            readonly
                            prepend-inner-icon
                            placeholder="Potencia del motor principal/s"
                            hint="Potencia del motor principal/s"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.power_unit_state || barco.power_unit"
                            readonly
                            hint="Unidad de Potencia"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title != 'CT'">
                          <v-text-field
                            :value="
                              barco.hull_material_state ||
                              (barco.hull_material || {}).nombre
                            "
                            readonly
                            hint="Material casco"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title == 'IT'">
                          <v-text-field
                            :value="barco.warehouses_number"
                            readonly
                            prepend-inner-icon
                            placeholder="Numero de bodegas"
                            hint="Numero de bodegas"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_high"
                            readonly
                            prepend-inner-icon
                            placeholder="Alto de la bodega de pescado (m)"
                            hint="Alto de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_long"
                            readonly
                            prepend-inner-icon
                            placeholder="Largo de la bodega de pescado (m)"
                            hint="Largo de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_width"
                            readonly
                            placeholder="Ancho de la bodega de pescado (m)"
                            hint="Ancho de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_capacity"
                            readonly
                            placeholder="Capacidad de la bodega de pescado (m³)"
                            hint="Capacidad de la bodega de pescado (m³)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              barco.fish_hold_type_state ||
                              (barco.fish_hold_type || {}).nombre
                            "
                            readonly
                            hint="Tipo de conservación del pescado"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3"></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Construcción</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            :value="barco.year_of_construction"
                            hint="Año de construcción"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :value="barco.construction_country"
                            hint="País de construcción"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Patente de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.date_expedition_patent"
                            hint="Fecha de inicio de vigencia de la patente"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="barco.date_expiration_patent"
                            hint="Fecha de vencimiento de la patente"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.number_of_crew"
                            hint="Numero de tripulantes"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.number_of_fishermens"
                            hint="Numero de pescadores"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Artes de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6">
                          <v-list>
                            <v-list-group>
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >Artes de Pesca</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <v-list-item
                                v-for="(item, index) in barco.fishing_arts
                                  .length > 0
                                  ? barco.fishing_arts
                                  : array_e"
                                :key="index"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.pivot.description"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :value="barco.fishing_arts_value"
                            hint="Valor del arte de pesca ($)"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-textarea
                            :value="barco.fishing_zone"
                            hint="Área de operaciones"
                            persistent-hint
                            readonly
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                              undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "UNO"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "UNO"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            (barco.fishing_arts || []).find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            :value="
                              barco.fishing_line_state || ((concepto.items_select_type_line || []).find(
                                    (x) => x.abbr == barco.fishing_line
                                  ) || {}).state
                            "
                            hint="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            :value="
                              barco.hook_type_state != null
                                ? barco.hook_type_state
                                : (concepto.items_select_type_hook || []).find(
                                    (x) => x.abbr == barco.hook_type
                                  ) != undefined
                                ? (concepto.items_select_type_hook || []).find(
                                    (x) => x.abbr == barco.hook_type
                                  ).state
                                : ''
                            "
                            hint="Tipo de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            :value="barco.hook_size"
                            hint="Tamaño de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.hooks_number"
                            hint="Cantidad de anzuelos"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.mother_line_length"
                            hint="Longitud de la línea madre medida en brazas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.mother_line_material_state != null
                                ? barco.mother_line_material_state
                                : (concepto.items_select_mother_line_material || []).find(
                                    (x) => x.abbr == barco.mother_line_material
                                  ) != undefined
                                ? (concepto.items_select_mother_line_material || []).find(
                                    (x) => x.abbr == barco.mother_line_material
                                  ).state
                                : ''
                            "
                            hint="Material de la línea madre"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.downpipe_material_state != null
                                ? barco.downpipe_material_state
                                : (concepto.items_select_downspout_material || []).find(
                                    (x) => x.abbr == barco.downpipe_material
                                  ) != undefined
                                ? (concepto.items_select_downspout_material || []).find(
                                    (x) => x.abbr == barco.downpipe_material
                                  ).state
                                : ''
                            "
                            hint="Material de las bajantes"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.total_lines_number"
                            hint="Cantidad total de líneas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                              undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "DOS"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "DOS"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.art_type_traps_state != null
                                ? barco.art_type_traps_state
                                : (concepto.items_select_denomination_art_fishing || []).find(
                                    (x) => x.abbr == barco.art_type_traps
                                  ) != undefined
                                ? (concepto.items_select_denomination_art_fishing || []).find(
                                    (x) => x.abbr == barco.art_type_traps
                                  ).state
                                : ''
                            "
                            hint="Denominación del arte de pesca tipo trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="barco.type_traps_number"
                            hint="Cantidad de trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                              undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.type_traps_material_state != null
                                ? barco.type_traps_material_state
                                : (concepto.items_select_main_trap_material || []).find(
                                    (x) => x.abbr == barco.type_traps_material
                                  ) != undefined
                                ? (concepto.items_select_main_trap_material || []).find(
                                    (x) => x.abbr == barco.type_traps_material
                                  ).state
                                : ''
                            "
                            hint="Material principal de la trampa o nasa"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "TRES"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "TRES"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.type_wound_artifacts_state != null
                                ? barco.type_wound_artifacts_state
                                : (concepto.items_select_type_artifact || []).find(
                                    (x) => x.abbr == barco.type_wound_artifacts
                                  ) != undefined
                                ? (concepto.items_select_type_artifact || []).find(
                                    (x) => x.abbr == barco.type_wound_artifacts
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="barco.amount_artifacts"
                            label="Cantidad de artefactos"
                            hint="Cantidad de artefactos"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.artifacts_material_state != null
                                ? barco.artifacts_material_state
                                : (concepto.items_select_artifact_material || []).find(
                                    (x) => x.abbr == barco.artifacts_material
                                  ) != undefined
                                ? (concepto.items_select_artifact_material || []).find(
                                    (x) => x.abbr == barco.artifacts_material
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-show="title != 'CP'">
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="
                              barco.type_fad_state != null
                                ? barco.type_fad_state
                                : (concepto.items_select_type_fad || []).find(
                                    (x) => x.abbr == barco.type_fad
                                  ) != undefined
                                ? (concepto.items_select_type_fad || []).find(
                                    (x) => x.abbr == barco.type_fad
                                  ).state
                                : ''
                            "
                            hint="Tipo de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="barco.fad_amount"
                            hint="Cantidad de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="barco.fad_components"
                            hint="Componentes del FAD"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" v-else>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{}">
                      <v-row no-gutters>
                        <v-col cols="4"
                          >{{ dataModal.boat.boat_name }} |
                          {{ dataModal.boat.registration_number }}</v-col
                        >
                        <v-col cols="3">
                          <v-icon>fa-ship</v-icon>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Datos del Registro</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.registration_number"
                            hint="Número de registro"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.boat_name"
                            hint="Nombre de la Embarcación"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            v-if="concepto.items_select_registration_port"
                            :value="
                              dataModal.boat.registration_port_state != null
                                ? dataModal.boat.registration_port_state
                                : (concepto.items_select_registration_port || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.registration_port
                                  ) != undefined
                                ? (concepto.items_select_registration_port || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.registration_port
                                  ).state
                                : ''
                            "
                            hint="Puerto de registro"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="dataModal.type == 'IND'">
                          <v-text-field
                            :value="
                              dataModal.boat.fishing_port_state
                                ? dataModal.boat.fishing_port_state
                                : (
                                    (concepto.items_select_authorized_landing_port_ind || []).find(
                                      (x) =>
                                        x.codigo == dataModal.boat.fishing_port
                                    ) || {}
                                  ).nombre
                            "
                            hint="Puerto de desembarque"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-else>
                          <v-text-field
                            :value="
                              dataModal.boat.fishing_port_state
                                ? dataModal.boat.fishing_port_state
                                : (
                                    (concepto.items_select_authorized_landing_port || []).find(
                                      (x) =>
                                        x.codigo == dataModal.boat.fishing_port
                                    ) || {}
                                  ).nombre
                            "
                            hint="Puerto de desembarque"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              dataModal.boat.boat_type_state != null
                                ? dataModal.boat.boat_type_state
                                : dataModal.boat.boat_type != null
                                ? dataModal.boat.boat_type.nombre
                                : ''
                            "
                            hint="Tipo de barco"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="
                              dataModal.boat.current_state_pavilion != null
                                ? (concepto.items_select_current_state_pavilion || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.current_state_pavilion
                                  ) != undefined
                                  ? (concepto.items_select_current_state_pavilion || []).find(
                                      (x) =>
                                        x.abbr ==
                                        dataModal.boat.current_state_pavilion
                                    ).state
                                  : ''
                                : ''
                            "
                            hint="Estado del pabellón"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.owner_name"
                            hint="Propietario"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              dataModal.boat.owner_type_id +
                              ':' +
                              dataModal.boat.owner_identification
                            "
                            hint="Identificación del Propietario"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.expedition_date"
                            hint="Fecha de Expedición de la Matricula"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.expiration_date"
                            hint="Fecha de Vencimineto de la Matricula"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.approx_boat_value"
                            hint="Valor Aproximado de la Embarcación"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.sell_products_to"
                            hint="Venderá los Productos a"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Dimensiones</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.length_overall"
                            readonly
                            prepend-inner-icon
                            placeholder="ESLORA - Longitud total (LOA)(m)"
                            hint="ESLORA - Longitud total (LOA)(m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          v-if="dataModal.boat.type_boat == 'ART'"
                        >
                          <v-text-field
                            :value="dataModal.boat.calado"
                            readonly
                            prepend-inner-icon
                            placeholder="Calado / Proyecto (m)"
                            hint="Calado / Proyecto (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.beam_extreme_breadth"
                            readonly
                            prepend-inner-icon
                            placeholder="Manga / extrema amplitud (m)"
                            hint="Manga / extrema amplitud (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.net_registered_tonnage"
                            readonly
                            prepend-inner-icon
                            hint="Tonelaje de Registro Neto (TRN)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.gross_registered_tonnage"
                            prepend-inner-icon
                            readonly
                            hint="Tonelaje de Registro Bruto (TRB)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          v-if="dataModal.boat.type_boat == 'ART'"
                        >
                          <v-text-field
                            :value="
                              dataModal.boat.type_of_propulsion_state ||
                              (dataModal.boat.type_of_propulsion || {}).nombre
                            "
                            readonly
                            hint="Tipo de propulsión."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.main_engines_power"
                            readonly
                            prepend-inner-icon
                            placeholder="Potencia del motor principal/s"
                            hint="Potencia del motor principal/s"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              dataModal.boat.power_unit_state ||
                              dataModal.boat.power_unit
                            "
                            readonly
                            hint="Unidad de Potencia"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title != 'CT'">
                          <v-text-field
                            :value="
                              dataModal.boat.hull_material_state ||
                              (dataModal.boat.hull_material || {}).nombre
                            "
                            readonly
                            hint="Material casco"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title == 'IT'">
                          <v-text-field
                            :value="dataModal.boat.warehouses_number"
                            readonly
                            prepend-inner-icon
                            placeholder="Numero de bodegas"
                            hint="Numero de bodegas"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.fish_hold_high"
                            readonly
                            prepend-inner-icon
                            placeholder="Alto de la bodega de pescado (m)"
                            hint="Alto de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.fish_hold_long"
                            readonly
                            prepend-inner-icon
                            placeholder="Largo de la bodega de pescado (m)"
                            hint="Largo de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.fish_hold_width"
                            readonly
                            placeholder="Ancho de la bodega de pescado (m)"
                            hint="Ancho de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.fish_hold_capacity"
                            readonly
                            placeholder="Capacidad de la bodega de pescado (m³)"
                            hint="Capacidad de la bodega de pescado (m³)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              dataModal.boat.fish_hold_type_state ||
                              (dataModal.boat.fish_hold_type || {}).nombre
                            "
                            readonly
                            hint="Tipo de conservación del pescado"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="title == 'CP'">
                          <v-text-field
                            :value="dataModal.boat.manager_operator"
                            readonly
                            hint="Nombre del gerente / operador / ARMADOR"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.beneficial_owner_name"
                            readonly
                            hint="Nombre del administrador o Agencia Marítima y empresa afiliadora en Colombia/Información de contacto"
                            persistent-hint
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3"></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" v-if="title == 'CT'">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Construcción</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6" v-if="title == 'CT'">
                          <v-text-field
                            :value="dataModal.boat.year_of_construction"
                            hint="Año de construcción"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6" v-if="title == 'CT'">
                          <v-text-field
                            :value="dataModal.boat.construction_country"
                            hint="País de construcción"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Patente de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3" v-if="title == 'CP'">
                          <v-text-field
                            :value="dataModal.boat.date_expedition_patent"
                            hint="Fecha de inicio de vigencia de la patente"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3" v-if="title == 'CP'">
                          <v-text-field
                            :value="dataModal.boat.date_expiration_patent"
                            hint="Fecha de vencimiento de la patente"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.number_of_crew"
                            hint="Numero de tripulantes"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="dataModal.boat.number_of_fishermens"
                            hint="Numero de pescadores"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="title === 'CP'">
                          <v-list>
                            <v-list-group>
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >Tipo de pesquería</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <v-list-item
                                v-for="item in dataModal.boat.type_fishery ||
                                array_e"
                                :key="item.id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.pivot.description"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-col>
                        <v-col cols="6" v-if="title == 'CP'">
                          <v-text-field
                            v-model="dataModal.valor_tasa_anual"
                            hint="Valor patente de pesca ($)"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="title == 'CP'">
                          <v-text-field
                            v-model="valor_tasa"
                            hint="Valor patente de pesca en letras($)"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Artes de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6">
                          <v-list>
                            <v-list-group>
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >Artes de Pesca</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <v-list-item
                                v-for="item in dataModal.boat.fishing_arts
                                  .length > 0
                                  ? dataModal.boat.fishing_arts
                                  : array_e"
                                :key="item.id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.pivot.description"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :value="dataModal.boat.fishing_arts_value"
                            hint="Valor del arte de pesca ($)"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-textarea
                            :value="dataModal.boat.fishing_zone"
                            hint="Área de operaciones"
                            persistent-hint
                            readonly
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              dataModal.boat.fishing_arts.find(
                                (x) => x.codigo == "UNO"
                              ) != undefined
                                ? dataModal.boat.fishing_arts.find(
                                    (x) => x.codigo == "UNO"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :value="
                              dataModal.boat.fishing_line_state != null
                                ? dataModal.boat.fishing_line_state
                                : (concepto.items_select_type_line || []).find(
                                    (x) => x.abbr == dataModal.boat.fishing_line
                                  ) != undefined
                                ? (concepto.items_select_type_line || []).find(
                                    (x) => x.abbr == dataModal.boat.fishing_line
                                  ).state
                                : ''
                            "
                            hint="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :value="
                              dataModal.boat.hook_type_state != null
                                ? dataModal.boat.hook_type_state
                                : (concepto.items_select_type_hook || []).find(
                                    (x) => x.abbr == dataModal.boat.hook_type
                                  ) != undefined
                                ? (concepto.items_select_type_hook || []).find(
                                    (x) => x.abbr == dataModal.boat.hook_type
                                  ).state
                                : ''
                            "
                            hint="Tipo de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            :value="dataModal.boat.hook_size"
                            hint="Tamaño de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="dataModal.boat.hooks_number"
                            hint="Cantidad de anzuelos"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="dataModal.boat.mother_line_length"
                            hint="Longitud de la línea madre medida en brazas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.mother_line_material_state != null
                                ? dataModal.boat.mother_line_material_state
                                : (concepto.items_select_mother_line_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.mother_line_material
                                  ) != undefined
                                ? (concepto.items_select_mother_line_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.mother_line_material
                                  ).state
                                : ''
                            "
                            hint="Material de la línea madre"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.downpipe_material_state != null
                                ? dataModal.boat.downpipe_material_state
                                : (concepto.items_select_downspout_material || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.downpipe_material
                                  ) != undefined
                                ? (concepto.items_select_downspout_material || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.downpipe_material
                                  ).state
                                : ''
                            "
                            hint="Material de las bajantes"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'UNO'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="dataModal.boat.total_lines_number"
                            hint="Cantidad total de líneas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              dataModal.boat.fishing_arts.find(
                                (x) => x.codigo == "DOS"
                              ) != undefined
                                ? dataModal.boat.fishing_arts.find(
                                    (x) => x.codigo == "DOS"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.art_type_traps_state != null
                                ? dataModal.boat.art_type_traps_state
                                : (concepto.items_select_denomination_art_fishing || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.art_type_traps
                                  ) != undefined
                                ? (concepto.items_select_denomination_art_fishing || []).find(
                                    (x) =>
                                      x.abbr == dataModal.boat.art_type_traps
                                  ).state
                                : ''
                            "
                            hint="Denominación del arte de pesca tipo trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="dataModal.boat.type_traps_number"
                            hint="Cantidad de trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'DOS'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.type_traps_material_state != null
                                ? dataModal.boat.type_traps_material_state
                                : (concepto.items_select_main_trap_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.type_traps_material
                                  ) != undefined
                                ? (concepto.items_select_main_trap_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.type_traps_material
                                  ).state
                                : ''
                            "
                            hint="Material principal de la trampa o nasa"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                            >{{
                              dataModal.boat.fishing_arts.find(
                                (x) => x.codigo == "TRES"
                              ) != undefined
                                ? dataModal.boat.fishing_arts.find(
                                    (x) => x.codigo == "TRES"
                                  ).nombre
                                : ""
                            }}</v-subheader
                          >
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.type_wound_artifacts_state != null
                                ? dataModal.boat.type_wound_artifacts_state
                                : (concepto.items_select_type_artifact || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.type_wound_artifacts
                                  ) != undefined
                                ? (concepto.items_select_type_artifact || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.type_wound_artifacts
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="dataModal.boat.amount_artifacts"
                            label="Cantidad de artefactos"
                            hint="Cantidad de artefactos"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          sm="12"
                          md="4"
                          v-show="
                            title != 'CP' &&
                            dataModal.boat.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.artifacts_material_state != null
                                ? dataModal.boat.artifacts_material_state
                                : (concepto.items_select_artifact_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.artifacts_material
                                  ) != undefined
                                ? (concepto.items_select_artifact_material || []).find(
                                    (x) =>
                                      x.abbr ==
                                      dataModal.boat.artifacts_material
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-show="title != 'CP'">
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="
                              dataModal.boat.type_fad_state != null
                                ? dataModal.boat.type_fad_state
                                : (concepto.items_select_type_fad || []).find(
                                    (x) => x.abbr == dataModal.boat.type_fad
                                  ) != undefined
                                ? (concepto.items_select_type_fad || []).find(
                                    (x) => x.abbr == dataModal.boat.type_fad
                                  ).state
                                : ''
                            "
                            hint="Tipo de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.fad_amount"
                            hint="Cantidad de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.fad_components"
                            hint="Componentes del FAD"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" v-if="title != 'CP'">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Ayudas a la Navegación</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.navigation_letters"
                            hint="Cartas de Navegación"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.magnetic_compass"
                            hint="Compas Magnetico"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.gps"
                            hint="GPS"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.loran"
                            hint="Loran"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.radar"
                            hint="Radar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.eco_probe"
                            hint="ECOSONDA"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3" v-show="title != 'CP'">
                          <v-text-field
                            readonly
                            :value="dataModal.boat.communication_radios"
                            hint="Radios de Comunicación"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-show="title == 'IT'">
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Datos del Representante</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="dataModal.nombre_representante"
                            hint="Nombre del Representante"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="dataModal.cargo_representante"
                            hint="Cargo del Representante"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="dataModal.tipo_identificacion_representante"
                            hint="Tipo de Identificacion del Representante"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="
                              dataModal.numero_identificacion_representante
                            "
                            hint="N° de Identificación del Representante"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" v-if="title == 'IT'">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Fotos de la Embarcación</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>

                        <v-col cols="12" v-if="title == 'IT'">
                          <v-gallery
                            :images="images"
                            :index="index"
                            @close="index = null"
                          >
                          </v-gallery>
                        </v-col>
                        <v-col
                          cols
                          class="image"
                          v-for="(image, imageIndex) in images"
                          :key="imageIndex"
                          @click="index = imageIndex"
                          :style="{
                            backgroundImage: 'url(' + image + ')',
                            width: '300px',
                            height: '150px',
                            backgroundSize: 'contain',
                          }"
                        ></v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <!--small>*indicates required field</small-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('handlePDF', 'Ok')"
            v-if="download"
            >Descargar PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { convert } from "../services/numToWord";
import { consultar } from "../services/variablesconcepto";
import { boatImageUrl, apiDomain } from "../config";
import { requestables } from "../enums/requestables";

export default {
  props: ["modal", "dataModal", "title", "download"],
  data: () => ({
    concepto: {},
    convert: convert,
    consecutive: "",
    path: boatImageUrl,
    apiDomain: apiDomain.substring(0, apiDomain.length - 1),
    lugar: "",
    index: null,

    items_select_authorized_arts: [
      { state: "1. SURROUNDING NETS – Red de Cerco", abbr: "UNO" },
      { state: "2. SEINE NETS – Redes de tiro", abbr: "DOS" },
      { state: "3. TRAWLS – Redes de arrastre", abbr: "TRES" },
      { state: "4. DREDGES – RASTRAS", abbr: "CUATRO" },
      { state: "5. LIFT NETS – Redes izadas", abbr: "CINCO" },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "7. GILLNETS AND ENTANGLING NETS- Red agallera y de enmalle",
        abbr: "SIETE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
      { state: "9. HOOKS AND LINES- Líneas y anzuelos", abbr: "NUEVE" },
      {
        state: "10. GRAPPLING AND WOUNDING – artes de aferrar o herir",
        abbr: "DIEZ",
      },
      {
        state: "11. HARVESTING MACHINES - Máquinas de recolección",
        abbr: "ONCE",
      },
      {
        state: "20. MISCELLANEOUS GEAR - artes diversos - polivalente",
        abbr: "VEINTE",
      },
      {
        state: "25. RECREATIONAL FISHING GEAR – artes de uso recreativo",
        abbr: "VEINTICINCO",
      },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "99. Unknown - Desconocido",
        abbr: "NOVENTAYNUEVE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
    ],
    boats_states: [
      {
        name: "vinculation_process",
        code: 0,
        label: "Caracteristicas de Motonave a Vincular",
      },
      {
        name: "linked",
        code: 1,
        label: "Caracteristicas de Motonave Vinculada",
      },
      {
        name: "desvinculation_process",
        code: 2,
        label: "Caracteristicas de Motonave a Desvincular",
      },
      {
        name: "unlinked",
        code: 3,
        label: "Caracteristicas de Motonave Desvinculada",
      },
    ],
    array_e: [
      {
        id: 1,
        nombre: "",
        pivot: {
          description: "",
        },
      },
    ],
  }),
  computed: {
    valor_tasa() {
      if (!this.dataModal.length) return;
      if (this.dataModal.valor_tasa_anual != null) {
        return this.convert(this.dataModal.valor_tasa_anual);
      } else {
        return "";
      }
    },
    images() {
      const images = this.dataModal?.boat?.images;

      const array_i = [];
      if (images) {
        images.map((x) => {
          array_i.push(this.path + x.nombre);
        });
      }
      return array_i;
    },
    boats() {
      if (!this.dataModal?.boats?.length) return;
      let _boats = this.dataModal.boat
        ? this.dataModal.boats.filter(
            (x) => Number(x.id) !== Number(this.dataModal.boat.id)
          )
        : this.dataModal.boats;
      let groups = [];
      this.boats_states.forEach((x) => {
        let group = _boats.filter((y) => Number(y.estado) === Number(x.code));
        if (group.length)
          groups.push({ title: x.label, code: x.code, data: group });
      });
      return groups;
    },
    requestables() {
      return requestables;
    },
  },
  async beforeCreate() {
    this.concepto = await consultar.fetch();
    this.items_select_authorized_arts =
      this.concepto.items_select_authorized_arts.map((x) => {
        return {
          state: x.nombre,
          abbr: x.codigo,
        };
      });
  },
};
</script>
